import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"
import ContentBlock from "../components/ContentBlock"
import SubTitle from "../components/SubTitle"
import UsedTech from "../components/UsedTech"

import "../components/Layout.css"

import DesignCornerImage from "../images/rectangle-md.svg"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  listImage,
  aboutImage,
  carriersImage,
  serviceProvidersImage,
  aboutTitle,
  aboutDescription,
  serviceProvidersTitle,
  serviceProvidersDescription,
  serviceProvidersDescription2,
  carriersTitle,
  carriersDescription,
  additionalBenefitsTitle,
  additionalBenefitsDescription,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK*/}
      <div className="relative md:flex container mx-auto py-10 md:py-24 pb-12 px-10 lg:px-32">
        <div className="lg:w-1/3">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
            {mainDescription}
          </p>
          <p className="mt-10 pb-8">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React Native</TechTag>
          </p>
          <p className="pb-8 md:pb-24">
            <TechTag>Mobile Payments</TechTag>
            <TechTag>SoftPOS</TechTag>
          </p>
        </div>
        <div className="lg:w-2/3 mt-10 lg:mt-0 sm:w-3/4 items-center z-20">
          {listImage && (
            <img
              src={listImage.childImageSharp.fixed.src}
              alt=""
              className="mx-auto w-3/4 md:-mt-20"
            />
          )}
        </div>
      </div>

      <div
        style={{
          background: "url(" + aboutImage.childImageSharp.fixed.src + ")",
          backgroundSize: "cover",
        }}
      >
        <ContentBlock className="md:pb-64 md:pt-64">
          <div className="md:absolute md:right-40 md:bottom-0 md:w-106 bg-gray-900 p-10 pb-20 text-white">
            <SubTitle bulletPoint>{aboutTitle}</SubTitle>
            <p>{aboutDescription}</p>
          </div>
        </ContentBlock>
      </div>

      <ContentBlock className="flex flex-row">
        <div className="md:w-1/2 md:pr-20 relative">
          <SubTitle bulletPoint>{serviceProvidersTitle}</SubTitle>
          <p className="mb-16">{serviceProvidersDescription}</p>
          <p className="md:pt-10 md:pl-10">{serviceProvidersDescription2}</p>
          <img
            src={DesignCornerImage}
            alt=""
            className="hidden md:block absolute w-12 opacity-50 top-64"
          />
        </div>
        <div className="mt-10 md:mt-0 md:w-1/2">
          <img
            src={serviceProvidersImage.childImageSharp.fixed.src}
            alt=""
            className=""
          />
        </div>
      </ContentBlock>

      <div className="bg-gray-300">
        <ContentBlock>
          <div>
            <SubTitle bulletPoint>{carriersTitle}</SubTitle>
            <div className="md:flex md:flex-row">
              <div className="md:w-1/2 mb-10 md:px-20 md:py-10 md:pl-0">
                <img
                  src={carriersImage.childImageSharp.fixed.src}
                  alt=""
                  className="md:relative bottom-0 right-0"
                />
              </div>
              <div className="md:w-1/2 md:flex items-center">
                <div>
                  <p>{carriersDescription}</p>
                  <p className="py-6 font-semibold">
                    {additionalBenefitsTitle}
                  </p>
                  <ul className="list-default leading-8 pl-5 text-gray-500">
                    {additionalBenefitsDescription.map((item, i) => (
                      <li key={i} className="pl-4">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ContentBlock>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <UsedTech title={title} AppDev SoftDev UiUx />
    </div>
  )
}

const Steero = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{project.frontmatter.title}</title>
            <meta
              name="description"
              content={project.frontmatter.mainDescription}
            />
          </Helmet>
        }
        content={project.html}
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default Steero

export const pageQuery = graphql`
  query SteeroID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        mainDescription
        aboutTitle
        aboutDescription
        serviceProvidersTitle
        serviceProvidersDescription
        serviceProvidersDescription2
        carriersTitle
        carriersDescription
        additionalBenefitsTitle
        additionalBenefitsDescription
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        aboutImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        carriersImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        serviceProvidersImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
